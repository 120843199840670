<template>
    <v-app>
        <v-main>

            <v-container class="">
                <v-row no-gutters style="height: 100vh;">
                    <v-col align-self="center" >
                        <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                                <v-col class="text-center" align="center" cols="12" s="8" md="5" lg="4">
                                    <div style="margin: auto;">
                                        <router-link :to="`/`" class="text-decoration-none text-white text-center">
                                            <v-toolbar-title >Short Url</v-toolbar-title>
                                        </router-link>
                                        <v-content>
                                            <slot></slot>
                                        </v-content>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>
  
  
  
<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "FormLayout",
});
</script>
  