<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="12" md="1" lg="3">
          </v-col>

          <v-col cols="12" md="10" lg="6">
            <v-row>
              <v-col cols="10">
                <router-link :to="`/`" class="text-decoration-none text-white">
                  <v-toolbar-title class="mr-4">Short Url</v-toolbar-title>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link :to="`/me`" class="text-md-right text-decoration-none text-white">
                  <p class="">Account</p>
                </router-link>
              </v-col>
            </v-row>

            <v-row>
              <v-col  class="pa-0">
                <v-content>
                  <slot></slot>
                </v-content>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="1" lg="3">
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>



<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DefaultLayout",
});
</script>
